import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { SideMenuStore } from './side-menu-store.types';
import {
  createSelectorHooks,
  ZustandHookSelectors,
} from 'auto-zustand-selectors-hook';

export const initialState = {
  isOpen: false,
  isPinned: false,
  isHidden: false,
};

export const useSideMenuStoreBase = create<SideMenuStore>()(
  devtools(
    immer(set => ({
      ...initialState,
      toggleOpen: () => set(state => ({ ...state, isOpen: !state.isOpen })),
      togglePin: () => set(state => ({ ...state, isPinned: !state.isPinned })),
      toggleIsHidden: (hide: boolean) =>
        set(state => ({ ...state, isHidden: hide })),
    })),
    {
      name: 'SideMenuStore',
      enabled: process.env.NODE_ENV !== 'production',
    },
  ),
);

export const useSideMenuStore = createSelectorHooks(
  useSideMenuStoreBase,
) as typeof useSideMenuStoreBase & ZustandHookSelectors<SideMenuStore>;

export * from './side-menu-store.types';

import { useOrdersHealthCheckStore } from './orders-health-check-store';

export const useGetOrderPrevalidationResults = (
  orderId: number,
  severity: 'warning' | 'error',
) =>
  useOrdersHealthCheckStore(state => {
    const prevalidation = state.prevalidation.results.find(
      result => result.orderId === orderId,
    );
    return severity === 'warning'
      ? prevalidation?.warnings
      : prevalidation?.errors;
  });

export const useGetOrderPrevalidationWarnings = (orderId: number) =>
  useGetOrderPrevalidationResults(orderId, 'warning');

export const useGetOrderPrevalidationErrors = (orderId: number) =>
  useGetOrderPrevalidationResults(orderId, 'error');

import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  createSelectorHooks,
  ZustandHookSelectors,
} from 'auto-zustand-selectors-hook';
import {
  OrdersHealthCheckState,
  OrdersHealthCheckStore,
} from './orders-health-check-store.types';
import { devtools } from 'zustand/middleware';

export const initialState: OrdersHealthCheckState = {
  prevalidation: { results: [], isLoading: true, isError: false },
};

export const useOrdersHealthCheckStoreBase = create<OrdersHealthCheckStore>()(
  devtools(
    immer(set => ({
      ...initialState,
      resetStore: () => set(initialState),
      setPrevalidationIsLoading: isLoading =>
        set(state => {
          state.prevalidation.isLoading = isLoading;
        }),
      setPrevalidationIsError: isError =>
        set(state => {
          state.prevalidation.isError = isError;
        }),
      setPrevalidationResults: results =>
        set(state => {
          state.prevalidation.results = results;
          state.prevalidation.isLoading = false;
          state.prevalidation.isError = false;
        }),
      addPrevalidationResults: results =>
        set(state => {
          const existingOrderIds = new Set(
            state.prevalidation.results.map(order => order.orderId),
          );
          results.forEach(result => {
            if (!existingOrderIds.has(result.orderId)) {
              state.prevalidation.results.push(result);
            } else {
              const existingOrder = state.prevalidation.results.find(
                order => order.orderId === result.orderId,
              );
              if (existingOrder) {
                existingOrder.errors = result.errors;
                existingOrder.warnings = result.warnings;
              } else {
                state.prevalidation.results.push(result);
              }
            }
          });
        }),
    })),
    {
      name: 'OrdersHealthCheckStore',
      enabled: process.env.NODE_ENV !== 'production',
    },
  ),
);

export const useOrdersHealthCheckStore = createSelectorHooks(
  useOrdersHealthCheckStoreBase,
) as typeof useOrdersHealthCheckStoreBase &
  ZustandHookSelectors<OrdersHealthCheckStore>;

export * from './orders-health-check-store.types';
